import React, { useRef, useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import styles from './NotificacaoViewComponent.module.css';
import { IoIosArrowDown } from "react-icons/io";
import apiAxios from '../../../api/apiAxios';
import { useGetLoggedUser } from '../../../actions/UserActions';
import { PropagateLoader } from 'react-spinners';

const NotificacaoViewComponent = ({ notificacao, onClose }) => {
    const contentRef = useRef(null);
    const [closing, setClosing] = useState(false);
    const { user } = useGetLoggedUser();
    const [interacoes, setInteracoes] = useState([]);
    const [loadingInteracoes, setLoadingInteracoes] = useState(true); // Estado para controle de carregamento

    useEffect(() => {
        if (user && notificacao) {
            marcarComoVisualizado();
            carregarInteracoes();
        }
    }, [user, notificacao]);

    // Carrega as interações da notificação ao abrir
    const carregarInteracoes = async () => {
        setLoadingInteracoes(true);
        try {
            const response = await apiAxios.get(`/api/avisos/${notificacao._id}`);
            setInteracoes(response.data.interacoes || []);
        } catch (error) {
            console.error("❌ Erro ao carregar interações:", error);
        } finally {
            setLoadingInteracoes(false);
        }
    };

    // Função para marcar como visualizada
    const marcarComoVisualizado = async () => {
        if (!notificacao.visualizadoPor?.includes(user._id)) {
            try {
                await apiAxios.post(`/api/avisos/${notificacao._id}/visualizar`, {
                    usuarioId: user._id
                });
                console.log("✅ Notificação marcada como visualizada!");
            } catch (error) {
                console.error("❌ Erro ao marcar como visualizada:", error);
            }
        }
    };

    // Função para interagir com a notificação (Curtir ou Amar)
    const interagirComAviso = async (tipo) => {
        if (!user) return;

        // Verifica se o usuário já interagiu
        const interacaoExistente = interacoes.find(interacao => interacao.usuarioId === user._id);

        if (interacaoExistente) {
            console.log("⚠️ Usuário já interagiu com essa notificação.");
            return;
        }

        try {
            const response = await apiAxios.post(`/api/avisos/${notificacao._id}/interagir`, {
                usuarioId: user._id,
                tipo
            });

            console.log(`✅ Interação "${tipo}" registrada com sucesso!`);

            // Atualiza a lista de interações
            setInteracoes(response.data.interacoes);
        } catch (error) {
            console.error(`❌ Erro ao adicionar interação "${tipo}":`, error);
        }
    };

    const handleClose = (e) => {
        if (contentRef.current && !contentRef.current.contains(e.target)) {
            setClosing(true);
        }
    };

    const handleAnimationEnd = () => {
        if (closing) {
            onClose();
        }
    };

    useEffect(() => {
        const contentElement = contentRef.current;
        contentElement.addEventListener('animationend', handleAnimationEnd);
        return () => {
            contentElement.removeEventListener('animationend', handleAnimationEnd);
        };
    }, [closing]);

    return (
        <div className={styles.allScreen} onClick={handleClose}>
            <div ref={contentRef} className={`${styles.content} ${closing ? styles.slideOut : styles.slideIn}`}>
                <div className={styles.header}>
                    <button onClick={() => setClosing(true)} className={styles.closeButton}>
                        <IoIosArrowDown />
                    </button>
                </div>
                <div className={styles.notificacaoDetalhes}>
                    <h2>{notificacao.titulo}</h2>
                    <p>{notificacao.descricao}</p>
                    <span className={styles.data}>
                        {new Date(notificacao.criadoEm).toLocaleDateString('pt-BR', {
                            weekday: 'long',
                            day: '2-digit',
                            month: 'long',
                            year: 'numeric'
                        })}
                    </span>

                    {/* Área de interações */}
                    <div className={styles.interacoes}>
                        {loadingInteracoes ? (
                            <div className={styles.sliderContainer} >
                                <PropagateLoader color="#EC3237" size={15} />
                            </div>
                        ) : (
                            <>
                                <button
                                    className={styles.botaoInteracao}
                                    onClick={() => interagirComAviso("Curtir")}
                                    disabled={interacoes.some(i => i.usuarioId === user._id)}
                                >
                                    👍 {interacoes.filter(i => i.tipo === "Curtir").length}
                                </button>
                                <button
                                    className={styles.botaoInteracao}
                                    onClick={() => interagirComAviso("Amar")}
                                    disabled={interacoes.some(i => i.usuarioId === user._id)}
                                >
                                    ❤️ {interacoes.filter(i => i.tipo === "Amar").length}
                                </button>
                            </>
                        )}
                    </div>
                </div>
            </div>
        </div>
    );
};

NotificacaoViewComponent.propTypes = {
    notificacao: PropTypes.shape({
        _id: PropTypes.string.isRequired,
        titulo: PropTypes.string.isRequired,
        descricao: PropTypes.string.isRequired,
        criadoEm: PropTypes.string.isRequired,
        visualizadoPor: PropTypes.arrayOf(PropTypes.string),
        interacoes: PropTypes.arrayOf(PropTypes.shape({
            usuarioId: PropTypes.string.isRequired,
            tipo: PropTypes.string.isRequired
        }))
    }).isRequired,
    onClose: PropTypes.func.isRequired
};

export default NotificacaoViewComponent;
