import React, { useEffect, useState, useRef } from 'react';
import styles from './HomeComponent.module.css';
import useTenantId from '../../../pages/tenantId';
import { GoHome, GoHomeFill } from 'react-icons/go';
import { FaBell, FaRegBell } from 'react-icons/fa6';
import { MdModeComment, MdOutlineModeComment } from 'react-icons/md';
import { TfiAgenda, TfiLayoutGrid2, TfiLayoutGrid2Alt } from 'react-icons/tfi';
import Loading from '../../../pages/Loading';
import BasicStructure from '../../BasicStructure';
import { Link } from 'react-router-dom';
import Slider from 'react-slick';
import { getTenants } from '../../../actions/UserActions';
import { PiAppleLogoBold, PiExam } from 'react-icons/pi';
import { RiEdit2Line, RiMegaphoneLine } from 'react-icons/ri';
import { useSelector } from 'react-redux';
import apiAxios from '../../../api/apiAxios';
import img from '../../../assets/Logos/Logotipo escola municipal minimalista azul e laranja.png';
import { CiCalendar } from 'react-icons/ci';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import imgNoImage from '../../../assets/noImage/image.png'
import { PropagateLoader } from 'react-spinners';
import { IoIosArrowForward } from 'react-icons/io';
import moment from 'moment';
import { convertFromRaw } from 'draft-js';
import { stateToHTML } from 'draft-js-export-html';
import { BsCalendarWeek } from 'react-icons/bs';
import HorariosComponent from '../Horarios/HorariosComponent';
import { useVerificarNotificacoesNaoLidas } from '../../../actions/NotificacoesActions';

const HomeComponent = ({ access }) => {
    const [loading, setLoading] = useState(true);
    const [tenants, setTenants] = useState([]);
    const [informes, setInformes] = useState([]);
    const [imagesLoaded, setImagesLoaded] = useState(false);
    const userInfo = useSelector(state => state.user.userInfo);
    const tokenPayload = JSON.parse(atob(userInfo.token.split('.')[1]));
    const tenantId = useTenantId();
    const sliderRef = useRef(null);
    const [calendario, setCalendario] = useState([]);

    const [user, setUser] = useState(null);
    const Id = tokenPayload?._id;

    const { temNotificacao } = useVerificarNotificacoesNaoLidas();

    const fetchUser = async () => {
        try {
            const response = await apiAxios.get(`/api/user/users/${tenantId}`);
            const user = response?.data?.users?.find((u) => u._id === Id);

            console.log('User fetched:', user);

            // Armazena todos os responsavelInfo no estado
            if (user?.responsavelInfo && user.responsavelInfo.length > 0) {
                setUser(user.responsavelInfo); // Armazena o array completo
            } else {
                setUser(null);
            }
        } catch (error) {
            console.error('Error retrieving user:', error);
        } finally {
            setLoading(false);
        }
    };

    useEffect(() => {
        fetchUser();
    }, [tenantId, Id]);

    // Extrai as séries e turnos
    const series = user?.map((info) => info.serie);
    const turnos = user?.map((info) => info.turno);

    console.log("Séries:", series);
    console.log("Turnos:", turnos);



    const itemsPageDeitado = [
        { icon: <GoHome />, iconSelected: <GoHomeFill />, link: "/", isSelected: true },
        { icon: <FaRegBell />, iconSelected: <FaBell />, link: "/notificacoes", isSelected: false },
        { icon: <MdOutlineModeComment />, iconSelected: <MdModeComment />, link: "/", isSelected: false },
        { icon: <TfiLayoutGrid2 />, iconSelected: <TfiLayoutGrid2Alt />, link: "/menu", isSelected: false }
    ];

    console.log(tokenPayload)

    const itemsPageEmPe = [...itemsPageDeitado];

    const settings = {
        dots: true,
        infinite: informes.length > 1, // Desativa o looping quando há apenas uma imagem
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1,
        arrows: false,
    };


    useEffect(() => {
        const fetchTenants = async () => {
            try {
                const response = await getTenants();
                const foundTenant = response.find(tenant => tenant._id === tokenPayload.tenantId);
                setTenants(foundTenant ? [foundTenant] : []);
            } catch (error) {
                console.error('Error fetching tenants:', error);
            } finally {
                setLoading(false);
            }
        };
        fetchTenants();
    }, [tokenPayload.tenantId]);

    useEffect(() => {
        const fetchInformes = async () => {
            if (!tenantId) return;
            try {
                const response = await apiAxios.get(`/api/inf/${tenantId}`);
                // Ordena os informes pela data mais próxima
                const sortedInformes = response.data.sort((a, b) => {
                    const dateA = new Date(a.data);
                    const dateB = new Date(b.data);
                    return dateA - dateB; // Ordem ascendente (mais próximo primeiro)
                });
                setInformes(sortedInformes);
            } catch (error) {
                console.error('Erro ao buscar informes:', error);
            }
        };
        fetchInformes();
    }, [tenantId]);


    useEffect(() => {
        if (informes.length > 0) {
            let loadedImages = 0;
            informes.forEach(informe => {
                const image = new Image();
                image.src = informe.imagem;
                image.onload = () => {
                    loadedImages++;
                    if (loadedImages === informes.length) {
                        setImagesLoaded(true);
                    }
                };
                image.onerror = () => console.error(`Erro ao carregar imagem: ${informe.imagem}`);
            });
        }
    }, [informes]);

    useEffect(() => {
        if (sliderRef.current && informes.length > 0) {
            sliderRef.current.slickGoTo(0);
        }
    }, [informes]);

    const renderImages = () => {
        if (informes.length > 0) {
            return informes
                .slice() // Cria uma cópia do array para evitar modificar o estado original
                .reverse() // Reverte a ordem do array
                .map((informe) => (
                    <div className={styles.sliderContainer} key={informe._id}>
                        <img
                            src={informe.imagem}
                            alt={informe.titulo}
                            width="1280"
                            height="720"
                        />
                        <div className={styles.informeDetails}>
                            <div className={styles.topico}>{informe.topico}</div>
                            <h2>{informe.titulo}</h2>
                            <p>{informe.descricao}</p>
                        </div>
                    </div>
                ));
        }
        return (
            <div className={styles.sliderContainer}>
                <img
                    src={imgNoImage}
                    alt="No Image Available"
                    className={styles.noImage}
                    width="1280"
                    height="720"
                />
            </div>
        );
    };

    const Functions = [
        { id: 2, title: "Agenda", icon: <TfiAgenda />, link: "/agenda" },
        { id: 4, title: "Informes", icon: <RiMegaphoneLine />, link: "/informes" },
        access === 'responsavel' ? { id: 6, title: "Calendário", icon: <CiCalendar />, link: "/calendario" } : null,
        { id: 1, title: "Atividades", icon: <RiEdit2Line />, link: "/" },
        { id: 3, title: "Cardápio", icon: <PiAppleLogoBold />, link: "/cardapio" },
        { id: 5, title: "Boletins", icon: <PiExam />, link: "/" }
    ].filter(Boolean);

    useEffect(() => {
        const fetchCalendario = async () => {
            try {
                const today = moment().format("YYYY-MM-DD"); // Data atual
                const response = await apiAxios.get(`/api/calendar/by-date/${today}`, {
                    params: { tenantId },
                });
                setCalendario(response.data); // Define os eventos no estado
            } catch (error) {
                console.error('Erro ao buscar calendário:', error);
            }
        };

        fetchCalendario(); // Chamada na montagem do componente
    }, [tenantId]);

    function formatDate(dateString) {
        console.log(dateString);

        // Ajusta o formato da data para ISO, caso necessário
        const [datePart, timePart] = dateString.split(' '); // Divide entre data e hora
        const [d, m, y] = datePart.split('/'); // Renomeia variáveis para evitar conflito
        const isoDateString = `${y}-${m}-${d}T${timePart}`; // Formato ISO

        const daysOfWeek = [
            "domingo",
            "segunda-feira",
            "terça-feira",
            "quarta-feira",
            "quinta-feira",
            "sexta-feira",
            "sábado",
        ];
        const months = [
            "janeiro",
            "fevereiro",
            "março",
            "abril",
            "maio",
            "junho",
            "julho",
            "agosto",
            "setembro",
            "outubro",
            "novembro",
            "dezembro",
        ];

        const date = new Date(isoDateString); // Cria o objeto Date com o formato ISO
        const dayNumber = date.getDate(); // Renomeia para evitar conflito
        const month = months[date.getMonth()];
        const year = date.getFullYear();
        const dayOfWeek = daysOfWeek[date.getDay()];

        console.log(`dia ${dayNumber} de ${month} de ${year}, ${dayOfWeek}`);
        return `Dia ${dayNumber} de ${month} de ${year}, ${dayOfWeek}`;
    }

    const renderCalendario = () => {
        if (calendario.length === 0) {
            return <div className={styles.noCalendar}>
                <div className={styles.top}>
                    Sem calendário para hoje.
                </div>
                <div className={styles.bottom}>
                    Que tal aproveitar para colocar os estudos em dia?
                </div>
            </div>;
        }

        return (
            <div className={styles.calendarList}>
                {calendario.map(evento => (
                    <div className={styles.content}>
                        <div className={styles.categoryColor} style={{ background: evento.categoriaColor, color: adjustColor(evento.categoriaColor, 130) }}>
                            <BsCalendarWeek />
                        </div>
                        <div key={evento.id} className={styles.conteudo}>
                            <p className={styles.category}>{evento.categoriaName}</p>
                            <h2>{evento.Titulo}</h2>
                            <p className={styles.date} style={{ fontSize: "14px", color: "gray" }}>
                                {formatDate(evento.date)}
                            </p>
                            <div className={styles.descricao}>
                                <div dangerouslySetInnerHTML={{ __html: convertDescriptionToHTML(evento.descricao) }} />
                            </div>
                        </div>
                    </div>

                ))}
            </div>
        );
    };

    const convertDescriptionToHTML = (descricao) => {
        if (!descricao) return '';
        try {
            const contentState = convertFromRaw(JSON.parse(descricao));
            return stateToHTML(contentState);
        } catch (error) {
            console.error('Erro ao converter descrição:', error);
            return 'Descrição inválida';
        }
    };

    const adjustColor = (hexColor, amount) => {
        let color = hexColor.replace('#', '');
        if (color.length === 3) {
            color = color.split('').map(char => char + char).join('');
        }

        const num = parseInt(color, 16);
        const r = (num >> 16) & 0xFF;
        const g = (num >> 8) & 0xFF;
        const b = num & 0xFF;

        // Soma dos valores RGB para avaliar a luminosidade
        const totalBrightness = r + g + b;

        // Verifica se a cor é muito escura (próxima do preto)
        if (totalBrightness < 60) {
            // Clarear a cor
            return `rgb(${Math.min(255, r + amount)}, ${Math.min(255, g + amount)}, ${Math.min(255, b + amount)})`;
        } else {
            // Escurecer a cor
            return `rgb(${Math.max(0, r - amount)}, ${Math.max(0, g - amount)}, ${Math.max(0, b - amount)})`;
        }
    };

    const ContentPage = (
        <div className={styles.screen}>
            <div className={styles.topHome}>
                {tenants.map(tenant => (
                    <div className={styles.contentimage}><img key={tenant._id} src={tenant.imageUrl} alt='logo' /></div>
                ))}
                <p className={styles.p1}>Olá, {tokenPayload.firstName}! 👋 </p>
                <p className={styles.p2}>Confira a sua agenda de hoje!</p>
            </div>
            <div className={styles.grid}>
                {informes.length > 0 && imagesLoaded ? (
                    <Slider {...settings} className={styles.sliderHome} ref={sliderRef}>
                        {renderImages()}
                    </Slider>
                ) : (
                    <div className={styles.sliderContainer} >
                        <PropagateLoader color="#EC3237" size={15} />
                    </div>
                )}
            </div>
            <p className={styles.titleHome}><Link style={{ textDecoration: "none", color: "#ec3237", display: "flex", justifyContent: "start", alignItems: "center" }} to={'/calendario'}>Calendário <IoIosArrowForward /></Link></p>
            <div className={styles.calendarSection}>
                {renderCalendario()}
            </div>
            <p className={styles.titleHome}>Horários</p>
            <HorariosComponent tenantId={tenantId} series={series} turno={[turnos]} />
            <p className={styles.titleHome}>Menu</p>
            <div className={styles.listFunctions}>
                {Functions.map(item => (
                    <Link key={item.id} to={item.link}>
                        <div className={styles.icon}>{item.icon}</div>
                        {item.title}
                    </Link>
                ))}
            </div>
        </div>
    );

    return (
        <>
            {loading ? <Loading /> : <div className={styles.screen}><BasicStructure itemsPageDeitado={itemsPageDeitado} itemsPageEmPe={itemsPageEmPe} ContentPage={ContentPage} temNotificacao={temNotificacao} /></div>}
        </>
    );
};

export default HomeComponent;
