import apiAxios from '../api/apiAxios'; 
import { useEffect, useState } from 'react';
import { useGetLoggedUser } from './UserActions';

export const useVerificarNotificacoesNaoLidas = () => {
    const { user, loading: loadingUser } = useGetLoggedUser(); // Obtém o usuário logado
    const [temNotificacao, setTemNotificacao] = useState(false);
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        const verificarNotificacoes = async () => {
            console.log("⏳ Iniciando verificação de notificações...");

            if (!user) {
                console.warn("⚠️ Nenhum usuário logado. Encerrando verificação.");
                setLoading(false);
                return;
            }

            try {
                console.log("📡 Buscando notificações do backend...");
                const response = await apiAxios.get("/api/avisos", { params: { status: "Ativo" } });
                console.log("📥 Notificações recebidas:", response.data);

                // Filtrando notificações com status true
                const notificacoesAtivas = response.data.filter(notificacao => notificacao.status === true);

                if (user?.responsavelInfo?.length > 0) {
                    // Pegando apenas a última série e turno do usuário
                    const ultimaInfo = user.responsavelInfo[user.responsavelInfo.length - 1];
                    const serieFinal = ultimaInfo.serie;
                    const turnoFinal = ultimaInfo.turno;

                    console.log("🔎 Última série permitida:", serieFinal);
                    console.log("🔎 Último turno permitido:", turnoFinal);

                    // Filtra notificações da última série e turno do usuário
                    const notificacoesUsuario = notificacoesAtivas.filter(notificacao =>
                        notificacao.acessoTurmas.some(turma =>
                            Array.isArray(turma.serie) && Array.isArray(turma.turno) &&
                            turma.serie.includes(serieFinal) &&
                            turma.turno.includes(turnoFinal)
                        )
                    );

                    console.log("📌 Notificações da série e turno do usuário:", notificacoesUsuario);

                    // Agora verificamos quantas dessas ainda não foram lidas
                    const notificacoesNaoLidas = notificacoesUsuario.filter(notificacao =>
                        !notificacao.visualizadoPor.includes(user._id)
                    );

                    console.log("🔴 Notificações não lidas encontradas:", notificacoesNaoLidas);

                    // Atualiza estado indicando se há notificações não lidas
                    setTemNotificacao(notificacoesNaoLidas.length > 0);
                } else {
                    console.log("⚠️ Usuário não possui séries ou turnos cadastrados.");
                }
            } catch (error) {
                console.error("❌ Erro ao verificar notificações:", error);
            } finally {
                console.log("✅ Finalizando verificação de notificações.");
                setLoading(false);
            }
        };

        if (!loadingUser) {
            console.log("🚀 Usuário carregado. Iniciando verificação de notificações...");
            verificarNotificacoes();
        } else {
            console.log("⌛ Aguardando carregamento do usuário...");
        }
    }, [user, loadingUser]);

    return { temNotificacao, loading };
};
