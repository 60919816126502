import apiAxios from '../api/apiAxios';
import Cookies from 'js-cookie';
import useTenantId from '../pages/tenantId';
import { useSelector } from 'react-redux';
import { useEffect, useState } from 'react';

export const signin = (email, password, tenantId) => async (dispatch) => {
    dispatch({ type: 'USER_SIGNIN_REQUEST', payload: { email, password, tenantId } });
    try {
        const { data } = await apiAxios.post('/api/user/LowerLogin', { email, password, tenantId });
        // Armazenar o token JWT como um cookie
        const userInfo = JSON.stringify(data);
        Cookies.set('userInfo', userInfo, { path: '/' });

        dispatch({ type: 'USER_SIGNIN_SUCCESS', payload: data });
    } catch (error) {
        if (error.response) {
            const status = error.response.status;
            if (status === 401) {
                dispatch({ type: 'USER_SIGNIN_FAILURE', payload: { general: '*Credenciais inválidas. Por favor, insira um usuário ou senha válido.' } });
            } else if (status === 500) {
                dispatch({ type: 'USER_SIGNIN_FAILURE', payload: { general: '*Houve um erro interno no servidor. Tente novamente mais tarde.' } });
            } else {
                dispatch({ type: 'USER_SIGNIN_FAILURE', payload: { general: '*Ocorreu um erro. Tente novamente mais tarde.' } });
            }
        } else {
            dispatch({ type: 'USER_SIGNIN_FAILURE', payload: { general: '*Ocorreu um erro. Tente novamente mais tarde.' } });
        }
    }
};

export const getUserDetails = (userId) => async (dispatch, getState) => {
    dispatch({ type: 'USER_DETAILS_REQUEST', payload: userId });
    try {
        const { data } = await apiAxios.get(`/api/user/user/${userId}`);
        dispatch({ type: 'USER_DETAILS_SUCCESS', payload: data });
    } catch (error) {
        dispatch({
            type: 'USER_DETAILS_FAILURE',
            payload:
                error.response && error.response.data.message
                    ? error.response.data.message
                    : error.message,
        });
    }
};

export const getTenants = async () => {
    try {
        const { data } = await apiAxios.get(`/api/user/tenants`);
        return data.tenants; // Retorne os tenants obtidos da API
    } catch (error) {
        console.error('Error fetching tenants:', error);
        throw error; // Lance o erro para que seja tratado no componente
    }
};

export const logout = () => {
    return {
        type: 'LOGOUT'
    };
};

export const clearUserInfo = () => {
    Cookies.remove('userInfo');
};


export const getAllUsers = async (tenentId) => {
    try {
        const res = await apiAxios.get(`api/user/users/${tenentId}`);
        return res.data.users;
    } catch (err) {
        console.error("Erro ao buscar todos os usuários:", err);
        throw err;
    }
};

export const deleteUserById = async (userId) => {
    try {
        const res = await apiAxios.delete(`api/user/user/${userId}`);
        return res.data.users; // ou retornar res diretamente, se necessário
    } catch (err) {
        console.error("Erro ao excluir usuário:", err);
        throw err;
    }
};

export const approveRequest = async (userId) => {
    try {
        const res = await apiAxios.post(`api/user/users/${userId}`);
        return res.data.users; // ou retornar res diretamente, se necessário
    } catch (err) {
        console.error("Erro ao excluir usuário:", err);
        throw err;
    }
};

export const useGetLoggedUser = () => {
    const userInfo = useSelector(state => state.user.userInfo); // Obtém o token do Redux
    const tenantId = useTenantId();
    const [user, setUser] = useState(null);
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        const fetchUser = async () => {
            if (!userInfo?.token) {
                console.warn("[useGetLoggedUser] Nenhum token encontrado.");
                setLoading(false);
                return;
            }

            try {
                // Decodifica o payload do token JWT para obter o ID do usuário
                const tokenPayload = JSON.parse(atob(userInfo.token.split('.')[1]));
                const userId = tokenPayload?._id;

                if (!userId) {
                    console.error("[useGetLoggedUser] ID do usuário não encontrado no token.");
                    setLoading(false);
                    return;
                }

                // Faz a requisição para obter os detalhes do usuário
                const response = await apiAxios.get(`/api/user/users/${tenantId}`);
                const foundUser = response?.data?.users?.find((u) => u._id === userId);
                setUser(foundUser || null);
            } catch (error) {
                console.error("[useGetLoggedUser] Erro ao buscar usuário:", error);
            } finally {
                setLoading(false);
            }
        };

        fetchUser();
    }, [userInfo, tenantId]);

    return { user, loading };
};

