import React, { useState, useEffect } from 'react';
import BasicPageStructure from '../../BasicPageStructure';
import apiAxios from '../../../api/apiAxios';
import styles from './NotificacoesComponent.module.css';
import NotificacaoViewComponent from './NotificacaoViewComponent';
import {
    IoMdCheckmarkCircle,
    IoMdWarning,
    IoMdHelpCircle,
    IoMdAlert,
    IoMdFlash
} from 'react-icons/io';
import { useGetLoggedUser } from '../../../actions/UserActions';
import { PropagateLoader } from 'react-spinners';

const NotificacoesComponent = () => {
    const [notificacoes, setNotificacoes] = useState([]);
    const [loading, setLoading] = useState(true);
    const { user, loading: loadingUser } = useGetLoggedUser();
    const [notificacaoSelecionada, setNotificacaoSelecionada] = useState(null);

    useEffect(() => {
        const fetchNotificacoes = async () => {
            setLoading(true);
            try {
                const response = await apiAxios.get('/api/avisos', { params: { status: "Ativo" } });
                console.log("🔍 Notificações recebidas:", response.data);
    
                if (!response.data || response.data.length === 0) {
                    console.warn("⚠️ Nenhuma notificação foi retornada pela API.");
                    setNotificacoes([]);
                    return;
                }
    
                // Filtra apenas notificações com status true
                const notificacoesAtivas = response.data.filter(notificacao => notificacao.status === true);
    
                if (user?.responsavelInfo?.length > 0) {
                    const seriesPermitidas = user.responsavelInfo.map(info => String(info.serie).trim().toLowerCase());
                    const turnosPermitidos = user.responsavelInfo.map(info => String(info.turno).trim().toLowerCase());
    
                    console.log("✅ Séries permitidas:", seriesPermitidas);
                    console.log("✅ Turnos permitidos:", turnosPermitidos);
    
                    const notificacoesFiltradas = notificacoesAtivas.filter(notificacao =>
                        notificacao.acessoTurmas?.some(turma => {
                            if (!turma.serie || !turma.turno) return false;
                            const serieNormalizada = String(turma.serie).trim().toLowerCase();
                            const turnoNormalizado = String(turma.turno).trim().toLowerCase();
    
                            return seriesPermitidas.includes(serieNormalizada) && turnosPermitidos.includes(turnoNormalizado);
                        })
                    );
    
                    console.log("🎯 Notificações filtradas:", notificacoesFiltradas);
                    setNotificacoes(notificacoesFiltradas);
                } else {
                    console.warn("⚠️ Usuário sem informações de responsável. Exibindo todas as notificações ativas.");
                    setNotificacoes(notificacoesAtivas);
                }
            } catch (error) {
                console.error("❌ Erro ao buscar notificações:", error);
                setNotificacoes([]);
            } finally {
                setLoading(false);
            }
        };
    
        if (user) {
            fetchNotificacoes();
        }
    }, [user]);
    

    const renderPriorityIcon = (prioridade) => {
        switch (prioridade) {
            case 1:
                return <IoMdCheckmarkCircle className={styles.prioridadeBaixa} title="Baixa Prioridade" />;
            case 2:
                return <IoMdWarning className={styles.prioridadeMediaBaixa} title="Prioridade Média Baixa" />;
            case 3:
                return <IoMdHelpCircle className={styles.prioridadeMedia} title="Prioridade Média" />;
            case 4:
                return <IoMdAlert className={styles.prioridadeAlta} title="Alta Prioridade" />;
            case 5:
                return <IoMdFlash className={styles.prioridadeUrgente} title="Urgente!" />;
            default:
                return null;
        }
    };

    return (
        <BasicPageStructure
            content={
                <div className={styles.notificacoesContainer}>
                    {loadingUser ? (

                        <div className={styles.sliderContainer} >
                            <PropagateLoader color="#EC3237" size={15} />
                        </div>
                    ) : loading ? (

                        <div className={styles.sliderContainer} >
                            <PropagateLoader color="#EC3237" size={15} />
                        </div>
                    ) : notificacoes.length > 0 ? (
                        <ul className={styles.listaNotificacoes}>
                            {notificacoes.map((notificacao) => {
                                const foiVisualizado = notificacao.visualizadoPor?.includes(user?._id);
                                return (
                                    <li
                                        key={notificacao._id}
                                        className={`${styles.notificacaoItem} ${!foiVisualizado ? styles.naoVisualizado : ''}`}
                                        onClick={() => setNotificacaoSelecionada(notificacao)}
                                    >
                                        <div className={styles.cabecalho}>
                                            <span className={`${styles.tipo} ${styles[notificacao.tipo?.toLowerCase()]}`}>
                                                {notificacao.tipo}
                                            </span>
                                            <strong>{notificacao.titulo}</strong>
                                        </div>
                                        <p>{notificacao.descricao}</p>
                                        <div className={styles.rodape}>
                                            <span className={styles.data}>
                                                {new Date(notificacao.criadoEm).toLocaleDateString('pt-BR', {
                                                    weekday: 'long',
                                                    day: '2-digit',
                                                    month: 'long',
                                                    year: 'numeric'
                                                })}
                                            </span>
                                            <span className={styles.prioridadeIcon}>
                                                {renderPriorityIcon(notificacao.prioridade)}
                                            </span>
                                        </div>
                                    </li>
                                );
                            })}
                        </ul>
                    ) : (
                        <p>❌ Nenhuma notificação disponível.</p>
                    )}

                    {notificacaoSelecionada && (
                        <NotificacaoViewComponent
                            notificacao={notificacaoSelecionada}
                            onClose={() => setNotificacaoSelecionada(null)}
                        />
                    )}
                </div>
            }
            link="/"
            namePage="Notificações"
        />
    );
};

export default NotificacoesComponent;
