import React, { useState, useEffect } from 'react';
import styles from './HorariosComponent.module.css';
import apiAxios from '../../../api/apiAxios';
import { IoMdArrowDropdown, IoMdArrowDropright } from 'react-icons/io';
import { PropagateLoader } from 'react-spinners';

const HorariosComponent = ({ tenantId, series = [], turno = [] }) => {
    const [horarios, setHorarios] = useState([]);
    const [loading, setLoading] = useState(true);
    const [expandedWeeks, setExpandedWeeks] = useState({});
    const [expandedHorario, setExpandedHorario] = useState(null);
    const [selectedDay, setSelectedDay] = useState("Segunda-feira"); // Inicializa com "Segunda-feira"

    useEffect(() => {
        const fetchHorarios = async () => {
            try {
                const response = await apiAxios.get(`/api/horarios/horarios`, { params: { tenantId } });
                setHorarios(response.data);
            } catch (error) {
                console.error("Erro ao obter horários:", error);
            } finally {
                setLoading(false);
            }
        };
        fetchHorarios();
    }, [tenantId]);

    const toggleWeek = (horarioId, weekDay) => {
        const key = `${horarioId}-${weekDay}`; // Chave única para cada série/turno
        setExpandedWeeks((prevState) => ({
            ...prevState,
            [key]: !prevState[key], // Alterna apenas para esse horário e dia
        }));
    };

    const toggleHorario = (materiaTitle) => {
        setExpandedHorario(expandedHorario === materiaTitle ? null : materiaTitle);
    };

    const filteredHorarios = horarios.filter((horario) => {
        const validSeries = Array.isArray(series) ? series.filter((s) => typeof s === 'string') : [];
        const validTurno = Array.isArray(turno[0]) ? turno[0].filter((t) => typeof t === 'string') : [];

        return (
            horario.tenantId === tenantId &&
            (validSeries.some((s) => s.trim() === horario.serie?.trim()) || validSeries.length === 0) &&
            (validTurno.some((t) => t.trim() === horario.turno?.trim()) || validTurno.length === 0) &&
            (!selectedDay || horario.week.some((week) => week.week_day === selectedDay))
        );
    });

    const daysOfWeek = ["Segunda-feira", "Terça-feira", "Quarta-feira", "Quinta-feira", "Sexta-feira"];

    return (
        <>
            {loading ? (
                <div className={styles.sliderContainer}>
                    <PropagateLoader color="#EC3237" size={15} />
                </div>
            ) : (
                <div className={styles.horariosList}>
                    {/* Botões para os dias da semana */}
                    <div className={styles.daysContainer}>
                        {daysOfWeek.map((day, index) => (
                            <button
                                key={index}
                                onClick={() => setSelectedDay(day)}
                                className={`${styles.dayButton} ${selectedDay === day ? styles.selected : ''}`}
                            >
                                {day}
                            </button>
                        ))}
                    </div>

                    {filteredHorarios.length > 0 ? (
                        filteredHorarios.map((horario) => (
                            <div key={horario._id} className={styles.horarioItem}>
                                <h2 className={styles.titulo}>
                                    {horario.serie} - {horario.turno}
                                </h2>
                                {horario.week
                                    .filter((week, index, self) =>
                                        index === self.findIndex((w) => w.week_day === week.week_day)
                                    ) // Remove duplicações
                                    .filter((week) => week.week_day === selectedDay) // Filtra apenas o dia selecionado
                                    .map((week) => {
                                        const key = `${horario._id}-${week.week_day}`;
                                        return (
                                            <div className={styles.semana_all} key={key}>
                                                <div
                                                    className={styles.semana}
                                                    onClick={() => toggleWeek(horario._id, week.week_day)}
                                                >
                                                    <strong>
                                                        {expandedWeeks[key] ? <IoMdArrowDropdown /> : <IoMdArrowDropright />}
                                                        {week.week_day}
                                                    </strong>
                                                    <p>({week.TimeStart} - {week.TimeEnd})</p>
                                                </div>

                                                {expandedWeeks[key] &&
                                                    week.materias.map((materia) => (
                                                        <div className={styles.horario} key={materia.title}>
                                                            <div
                                                                className={styles.horario_top}
                                                                onClick={() => toggleHorario(materia.title)}
                                                            >
                                                                <p>
                                                                    {expandedHorario === materia.title ? <IoMdArrowDropdown /> : <IoMdArrowDropright />}
                                                                    {materia.title}
                                                                </p>
                                                                <p>({materia.TimeStart} - {materia.TimeEnd})</p>
                                                            </div>
                                                            {expandedHorario === materia.title && (
                                                                <div className={styles.horario_bottom}>
                                                                    {materia.conteudo}
                                                                </div>
                                                            )}
                                                        </div>
                                                    ))}
                                            </div>
                                        );
                                    })}
                            </div>
                        ))
                    ) : (
                        <div className={styles.notFound}>
                            <p>Nenhuma Matéria Encontrada!</p>
                        </div>
                    )}
                </div>
            )}
        </>
    );
};

export default HorariosComponent;
